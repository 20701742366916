import type Keycloak from "keycloak-js";
import { EditForm } from "./p-edit-form-component";

export class AccountManager {
  private baseline?: DTO;

  private readonly firstNameInput: HTMLInputElement;
  private readonly lastNameInput: HTMLInputElement;
  private readonly dobInput: HTMLInputElement;
  private readonly accountCardElement: HTMLElement;
  private readonly accountForm: EditForm;
  private readonly accountBody: HTMLBodyElement;
  private readonly accountGenericError: HTMLDivElement;

  private readonly keycloak: Keycloak;
  private readonly dataTransmissionButton: HTMLButtonElement;
  private readonly dataAccessButton: HTMLButtonElement;
  private readonly logoutAllButton: HTMLElement;
  private readonly logoutAcceptButtonRow: HTMLElement;
  private readonly logoutAcceptButton: HTMLElement;
  private readonly logoutCancelButton: HTMLElement;

  private portabilityState?: PortabilityState;

  constructor(keycloak: Keycloak) {
    this.firstNameInput = document.getElementById(
      "firstName",
    ) as HTMLInputElement;
    this.lastNameInput = document.getElementById(
      "lastName",
    ) as HTMLInputElement;
    this.dobInput = document.getElementById("dob") as HTMLInputElement;
    this.accountCardElement = document.getElementById("account-card");
    this.accountBody = document.querySelector("body") as HTMLBodyElement;
    this.accountGenericError = document.getElementById(
      "generic-error",
    ) as HTMLDivElement;
    this.keycloak = keycloak;
    this.dataTransmissionButton = document.getElementById(
      "request-data-transmission-button",
    ) as HTMLButtonElement;
    this.dataAccessButton = document.getElementById(
      "request-data-access-button",
    ) as HTMLButtonElement;
    this.logoutAllButton = document.getElementById("logout-all-button");
    this.logoutAcceptButtonRow = document.getElementById(
      "logout-accept-button-row",
    );
    this.logoutAcceptButtonRow.hidden = true;
    this.logoutAcceptButton = document.getElementById("logout-accept-button");
    this.logoutCancelButton = document.getElementById("logout-cancel-button");

    document
      .getElementById("change-password-button")
      .addEventListener("click", (evt) => {
        evt.preventDefault();
        this.runRequiredAction("UPDATE_PASSWORD");
      });

    document
      .getElementById("change-email-button")
      .addEventListener("click", (evt) => {
        evt.preventDefault();
        this.runRequiredAction("UPDATE_EMAIL");
      });

    this.dataTransmissionButton.addEventListener("click", (evt) => {
      evt.preventDefault();
      this.handleTransferClick();
    });

    this.dataAccessButton.addEventListener("click", (evt) => {
      evt.preventDefault();
      this.handleDataAccessRequestRequiredAction().catch(() =>
        this.setNegativeFeedback(this.dataAccessButton),
      );
    });

    this.logoutAllButton.addEventListener("click", (ev) => {
      ev.preventDefault();
      this.setPositiveFeedback(
        this.logoutAllButton,
        "Möchtest du dich wirklich überall abmelden? Bitte beachte, dass die Abmeldung bis zu 10 Min. dauern kann.",
      );
      this.logoutAllButton.hidden = true;
      this.logoutAcceptButtonRow.hidden = false;
    });

    this.logoutAcceptButton.addEventListener("click", (ev) => {
      ev.preventDefault();
      this.runRequiredAction("logout-all-sessions").catch(() =>
        this.setNegativeFeedback(this.logoutAllButton),
      );
    });

    this.logoutCancelButton.addEventListener("click", (ev) => {
      ev.preventDefault();
      this.logoutAcceptButtonRow.parentNode.querySelector(".alert").remove();
      this.logoutAllButton.hidden = false;
      this.logoutAcceptButtonRow.hidden = true;
    });

    this.accountForm = document.getElementById("account-form") as EditForm;
    this.accountForm.addEventListener("save", (evt) => {
      const detail = (evt as CustomEvent).detail as SaveEventDetail;
      this.saveCurrentState(detail)
        .then(() => {
          this.accountForm.generalError = false;
        })
        .catch((err) => {
          this.accountForm.generalError = true;
          console.error(err);
        });
    });
    this.accountForm.addEventListener("cancel", () => {
      this.resetToSaved(true);
      this.setErrors();
    });
    window.addEventListener("hashchange", this.setContentVisibility);
    this.setContentVisibility();
  }

  private handleTransferClick() {
    if (this.portabilityState === "READY") {
      const fakeDownload: HTMLElement = document.createElement("div");
      const normalizedUrl = !window.location.pathname.endsWith("/")
        ? window.location.pathname + "/"
        : window.location.pathname;
      fakeDownload.innerHTML = `<a href="${normalizedUrl}../portability-delivery/" download="gdpr_portability.html"></a>`;
      (fakeDownload.querySelector("a") as HTMLAnchorElement).click();
    } else {
      this.runRequiredAction("perform-gdpr-right-of-portability-request").catch(
        () => this.setNegativeFeedback(this.dataTransmissionButton),
      );
    }
  }

  private async handleDataAccessRequestRequiredAction() {
    await this.keycloak.login({
      action: "perform-gdpr-right-of-info-request",
    });
  }

  private async runRequiredAction(action: Action) {
    await this.keycloak.login({ action });
  }

  private async getToken(): Promise<string> {
    if (this.keycloak.token) {
      return new Promise((resolve) => resolve(this.keycloak.token));
    }
    await this.keycloak.updateToken(5);
    return this.keycloak.token;
  }

  private async getAccountInfo(): Promise<DTO> {
    if (this.baseline) {
      return new Promise((resolve) => resolve(this.baseline));
    }
    const token = await this.getToken();
    const r: Response = await fetch(
      `${this.accountUrl()}?userProfileMetadata=false`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const j: DTO = await r.json();
    this.baseline = j;

    if (j.attributes["doi-received-on"]) {
      const doiReceivedOnDate = new Date(j.attributes["doi-received-on"][0]);
      if (doiReceivedOnDate.getTime() + 5 * 60000 > Date.now()) {
        this.setPositiveFeedback(
          this.dataAccessButton,
          "Die Funktion steht dir in 2-3 Minuten zur Verfügung. Schau einfach gleich noch einmal vorbei.",
        );
        this.dataAccessButton.hidden = true;
      }
    }
    if (this.baseline.attributes.lastGdprRequestDate) {
      const lastGdprRequestDate = new Date(
        this.baseline.attributes.lastGdprRequestDate[0],
      );
      // check if lastGdprRequestDate is more than 28 days ago
      const millisecondsInADay = 24 * 60 * 60 * 1000;
      const daysToMilliseconds = 28 * millisecondsInADay;
      if (lastGdprRequestDate.getTime() + daysToMilliseconds < Date.now()) {
        // If the last GDPR request date is more than 28 days ago, we can enable the button
        return;
      }
      this.setPositiveFeedback(this.dataAccessButton);
    }
    return j;
  }

  setPositiveFeedback(button: HTMLElement, text: string = null) {
    const alerts = button.parentNode.querySelectorAll(".alert");
    if (alerts.length > 0) {
      for (let i = 0; i < alerts.length; i++) {
        alerts[i].remove();
      }
    }
    const waitingAlertElement = document.createElement("div");
    waitingAlertElement.setAttribute("role", "alert");
    waitingAlertElement.classList.add("alert");
    waitingAlertElement.classList.add("alert__info");
    waitingAlertElement.innerText =
      text ??
      "Deine Daten werden bereitgestellt. Du erhältst eine Email, sobald das Datenarchiv bereitsteht.";
    button.parentNode.insertBefore(waitingAlertElement.cloneNode(true), button);
    button.hidden = true;
  }

  setNegativeFeedback(button: HTMLElement, text?: string) {
    const alerts = button.parentNode.querySelectorAll(".alert");
    if (alerts.length > 0) {
      for (let i = 0; i < alerts.length; i++) {
        alerts[i].remove();
      }
    }
    const errorAlertElement = document.createElement("div");
    errorAlertElement.setAttribute("role", "alert");
    errorAlertElement.classList.add("alert");
    errorAlertElement.classList.add("alert__error");
    errorAlertElement.innerText =
      text ??
      "Leider können wir deine Anfrage zur Zeit nicht bearbeiten. Wir arbeiten bereits an einer Lösung.";
    button.parentNode.insertBefore(errorAlertElement.cloneNode(true), button);
  }

  resetToSaved(hard?: boolean) {
    if (hard) {
      this.baseline = null;
    }
    this.getAccountInfo()
      .then(() => this.updateUI())
      .catch((err) => {
        console.log(err);
        this.accountBody.hidden = false;
        this.accountGenericError.hidden = false;
      });
  }

  private safeSetTextContent(selector: string, value: string, root?: Element) {
    const target = (root || document).querySelectorAll(selector);

    if (target.length > 0) {
      target.forEach((el) => {
        el.textContent = value;
      });

    }
  }

  private updateUI() {
    this.accountBody.hidden = false;
    this.accountGenericError.hidden = true;
    const { firstName, lastName, attributes, username } = this.baseline;
    setValue(this.firstNameInput, firstName);
    setValue(this.lastNameInput, lastName);
    const dob = attributes?.dob?.at(0);
    setValue(this.dobInput, dob);
    this.dobInput.classList.toggle("filled__date", !!dob);
    const name = [...[firstName], ...[lastName]].join(" ");
    const phonePin = attributes?.phonePin?.at(0) || "Leider nicht verfügbar";

    this.safeSetTextContent(".name", name, this.accountCardElement);
    this.safeSetTextContent(".mail", username, this.accountCardElement);
    this.safeSetTextContent(".phonePin", phonePin);

    this.handleTransferStatus();
  }

  private handleTransferStatus() {
    const statusStrings = this.baseline.attributes["portability-status"];
    this.portabilityState = null;

    if (statusStrings && statusStrings[0]) {
      const { state, until } = JSON.parse(statusStrings[0]);
      const untilDate = until && Date.parse(until);

      if (state === "PENDING") {
        if (untilDate && untilDate < Date.now()) {
          this.setNegativeFeedback(
            this.dataTransmissionButton,
            "Es ist leider etwas schief gelaufen, bitte versuche es nochmal",
          );
        } else {
          this.portabilityState = "PENDING";
        }
      } else if (state === "SUCCEEDED") {
        if (Date.now() <= untilDate) {
          this.portabilityState = "READY";
        }
      }
    }

    this.configureTransferButton();
  }

  private configureTransferButton() {
    const button = this.dataTransmissionButton;
    if (this.portabilityState === "PENDING") {
      button.disabled = true;
      this.setPositiveFeedback(
        button,
        "Deine Daten werden bereitgestellt. Du erhältst eine Mitteilung in der Penny App.",
      );
    } else {
      if (this.portabilityState === "READY") {
        button.textContent = "Daten herunterladen";
      }
      button.disabled = false;
      delete button.title;
    }
  }

  private async getCurrentState(detail: SaveEventDetail): Promise<DTO> {
    const base = await this.getAccountInfo();
    return {
      ...base,
      firstName: detail.firstName,
      lastName: detail.lastName,
      attributes: {
        ...base.attributes,
        dob: [detail["user.attributes.dob"]],
      },
    };
  }

  private async saveCurrentState(detail: SaveEventDetail) {
    const [dto, token] = await Promise.all([
      this.getCurrentState(detail),
      this.getToken(),
    ]);
    const response = await fetch(this.accountUrl(), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      body: JSON.stringify(dto),
    });
    if (response.status === 400) {
      const json = await response.json();
      this.accountForm.setAttribute("editing", "");
      this.setErrors(json);
    } else {
      this.setErrors();
    }
    this.baseline = dto;
    this.resetToSaved();
    if (response.status > 400) {
      throw new Error(`request failed: ${response.status}`);
    }
  }

  private setErrors(errors?: Errors) {
    const inputs = document.querySelectorAll(".form__input");
    const noErrors = !errors || !errors.errors;
    if (noErrors) {
      inputs.forEach((input) => {
        const messageElement = input.parentElement.querySelector(
          ".form__error",
        ) as HTMLElement;
        if (!messageElement) {
          return;
        }
        input.classList.remove("form__input--invalid");
        messageElement.hidden = true;
      });
      return;
    }
    const errorsByField = errors.errors.reduce((acc, val) => {
      acc[val.field] = val.errorMessage;
      return acc;
    }, {});
    inputs.forEach((input) => {
      const error = errorsByField[input.id];
      const messageElement = input.parentElement.querySelector(
        ".form__error",
      ) as HTMLElement;
      if (!messageElement) {
        return;
      }
      if (error) {
        input.classList.add("form__input--invalid");
        messageElement.hidden = false;
        messageElement.textContent = (window as any).l18nMsg[error];
      } else {
        input.classList.remove("form__input--invalid");
        messageElement.hidden = true;
        messageElement.textContent = "";
      }
    });
  }

  private baseUrl() {
    return `${this.keycloak.authServerUrl}realms/penny`;
  }

  private accountUrl() {
    return `${this.baseUrl()}/account/`;
  }

  private setContentVisibility() {
    let hash = window.location.hash;
    if (
      !hash ||
      hash.startsWith("#state") ||
      hash.startsWith("#&state") ||
      hash.startsWith("#uc-corner-modal-show")
    ) {
      hash = "#menu";
    }
    [
      "menu",
      "personal-info",
      "email-password",
      "ad-preferences",
      "data-and-devices",
      "delete-account",
    ].forEach((id) => {
      const el = document.getElementById(id);
      el.hidden = !hash.startsWith("#" + id);
      if (!el.hidden && hash !== "#menu") {
        el.scrollIntoView(true);
      }
    });
    switch (hash) {
      case "#menu":
        trackSinglePageView("overview");
        break;
      case "#personal-info":
        trackSinglePageView("personal");
        break;
      case "#email-password":
        trackSinglePageView("email_and_password");
        break;
      case "#ad-preferences":
        trackSinglePageView("ad_preferences");
        break;
      case "#data-and-devices":
        trackSinglePageView("data_and_devices");
        break;
      // delete-account not listed in tracking implementation guide -> no tracking
    }
  }
}

function trackSinglePageView(pageName: string) {
  const fn = (window as any).trackSinglePageView;
  if (fn) {
    fn(pageName);
  }
}

function setValue(input: HTMLInputElement, value: string | undefined) {
  input.value = value || "";
  // we need to set this for the input css to work properly
  input.setAttribute("value", value || "");
}

type PortabilityState = "PENDING" | "READY";

export type Action =
  | "UPDATE_EMAIL"
  | "UPDATE_PASSWORD"
  | "logout-all-sessions"
  | "perform-gdpr-right-of-portability-request";
export type Field = "firstName" | "lastName" | "dob";

type KeyValueArray = {
  [key: string]: string[];
};

interface Attributes {
  dob?: string[];
  phonePin?: string[];
  lastGdprRequestDate?: string[];
  "doi-received-on"?: string[];
}

interface DTO {
  attributes?: Attributes & KeyValueArray;
  firstName?: string;
  lastName?: string;
  username?: string;
}

interface Errors {
  errors?: {
    field: Field;
    errorMessage: string;
  }[];
}

interface SaveEventDetail {
  firstName: string;
  lastName: string;
  ["user.attributes.dob"]: string;
}
